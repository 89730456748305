// Optimizely version 1
export const WAREHOUSE_TABLE_INFO_PAGE = 'warehouse-table-info-page';
export const WAREHOUSE_DDC_CREATE_NEW_BUTTON = 'warehouse-ddc-create-new-button';
export const WAREHOUSE_TABLE_CLICK_DETAILS = 'warehouse-table-click-details';
export const WAREHOUSE_TRANSLATE_3P = 'warehouse-translate-3p';
export const WAREHOUSE_TABLE_PAGINATION = 'warehouse-table-pagination';
export const WAREHOUSE_EDIT_COVERAGE_AREA = 'warehouse-edit-coverage-area';
export const WAREHOUSE_SELECTED_LANGUAGE = 'warehouse-selected-language';
export const WAREHOUSE_ALLOW_SAME_STATE_COVERAGE_AREA = 'warehouse-allow-same-state-coverage-area';
export const WAREHOUSE_EDIT_DISTRIBUTION_CENTER = 'warehouse-edit-distribution-center';
export const WAREHOUSE_NEW_EDIT_DDC_FLOW = 'warehouse-new-edit-ddc-flow';
export const WAREHOUSE_VALIDATE_SAVE_COVERAGE_AREA_WITHOUT_CHANGES =
  'warehouse-validate-save-coverage-area-without-changes';
